import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API } from './global';
import { IApiRequestGet, IApiRequestPost } from '../types/api';
import { hidePreloader, hideRequestIndicator } from '../stores/preloader';
import { user } from '../stores/user';
import { showAlert } from '../stores/alert';

export const Api = axios.create( {
	baseURL: API.url,
	timeout: API.timeout,
} );

export const getHeaders = ( data: any ) => {
	const headers: any = {
		'Web-app': true
	};

	if ( !( data instanceof FormData ) ) {
		// @ts-ignore
		headers[ 'Content-Type' ] = 'application/json';
	}

	if ( user.token ) {
		// @ts-ignore
		headers.Authorization = `Bearer ${ user.token }`;
	}

	return headers;
};

// @ts-ignore
Api.interceptors.request.use( ( config: AxiosRequestConfig ) => {
	config.headers = { ...config.headers, ...getHeaders( config.data ) };
	return config;
} );

const successHandler = ( response: any ) => {
	hideRequestIndicator();

	if ( response.message === 'Unauthenticated.' || response.code === 'Unauthorize' || response.status === 401 ) {
		user.logout();
		return;
	}

	if ( response.status === 400 ) {
		showAlert( {
			type: 'error',
			message: response.data.message,
		} );
	}

	return response.data;
};

const errorHandler = ( error: AxiosError ) => {
	hideRequestIndicator();
	hidePreloader();

	//@ts-ignore
	if ( error.response?.message === 'Unauthenticated.' || error.response?.code === 'Unauthorize' || error.response?.status === 401 ) {
		user.logout();
		return;
	}

	if ( error.response?.status === 400 ) {
		showAlert( {
			type: 'error',
			// @ts-ignore
			message: error.response?.data?.message,
		} );
	}

	throw error;
};

export const apiPost = async ( { url, postData }: IApiRequestPost ) => {
	return await Api.post( url, postData )
		.then( res => successHandler( res ) )
		.catch( e => errorHandler( e ) );
};

export const apiGet = async ( { url }: IApiRequestGet ) => {
	return Api.get( url )
		.then( res => successHandler( res ) )
		.catch( e => errorHandler( e ) );
};

export const apiPatch = async (
	{
		url,
		postData,
	}: IApiRequestPost ) => {

	return await Api.patch( url, postData )
		.then( res => successHandler( res ) )
		.catch( e => errorHandler( e ) );
};

export const apiDelete = async (
	{
		url,
		postData,
		headerList = {},
	}: IApiRequestPost ) => {
	return await Api.delete( url, {
		data: postData,
		headers: headerList,
	} )
		.then( res => successHandler( res ) )
		.catch( errorHandler );
};
