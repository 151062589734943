import React, { useEffect } from 'react';
import { hidePreloader, showPreloader } from "../stores/preloader";

const LoadPage = () => {
	showPreloader();

	useEffect( () => {
		return () => {
			hidePreloader();
		}
	} );

	return <></>;
}

export default LoadPage;
