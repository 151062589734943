import React from 'react';
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as Error } from "../../assets/img/404.svg";
import { Link } from "react-router-dom";
import { parseDate } from "../../util/util";
import { hidePreloader } from "../../stores/preloader";

const NotFound = () => {
	hidePreloader();

	return (
		<div className="page not-found">
			<section className="error-page">
				<div className="container">
					<div className="error-page-flex">
						<Link to="/" className="logo">
							<Logo/>
						</Link>
						<Error/>
						<h1 className="h2">Страница не найдена</h1>
						<p>Так уж получилось, что из множества страниц нашего сайта Вы оказались как раз на той, которая уже не
							существует…
						</p>
						<Link to="/" className="btn btn-primary">Вернуться на главную</Link>
						<div className="copyright">© Бизнестур.онлайн { parseDate().format( 'YYYY' ) }</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default NotFound;
