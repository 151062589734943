import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './fonts/font-awesome/scss/font-awesome.scss';
import './App.scss';
import './style/style.scss';
import './style/svg.scss';
import 'moment/locale/ru';
import GuestRoute from './components/GuestRoute';
import AuthRoute from './components/AuthRoute';
import { getConfig } from './stores/config';
import { $userStore, user } from './stores/user';
import NotFound from './pages/Errors/NotFound';
import LoadPage from './components/LoadPage';
import Preloader from './components/Preloader';
import LoadIntegrations from './integrations/LoadIntegrations';
import Modal from './components/Modal';
import Message from './components/Message';
import Overlay from './components/Overlay';
import TravelPoliciesModal from './components/modal/TravelPoliciesModal';
import RequestIndicator from './components/Request/RequestIndicator';
import moment from 'moment';
import { useStore } from 'effector-react';

moment.locale( 'ru' );

const Login = lazy( () => import('./pages/Login/Login') );
const Registration = lazy( () => import('./pages/Registration/Registration') );
const ResetPassword = lazy( () => import('./pages/Registration/ResetPassword') );
const ForgotPassword = lazy( () => import('./pages/Registration/ForgotPassword') );
const Home = lazy( () => import('./pages/Home/Home') );
const Search = lazy( () => import('./pages/Search/Search') );
const AviaSearch = lazy( () => import('./pages/AviaSearch/AviaSearch') );
const TrainSearch = lazy( () => import('./pages/TrainSearch/TrainSearch') );
const HotelSearch = lazy( () => import('./pages/HotelSearch/HotelSearch') );
const Customers = lazy( () => import('./pages/Customers/Customers') );
const Trips = lazy( () => import('./pages/Trips/Trips') );
const Trip = lazy( () => import('./pages/Trip/TripPage') );
const Documents = lazy( () => import('./pages/Documents/Documents') );
const Pdf = lazy( () => import('./pages/Pdf/PdfView') );

//Company
const Company = lazy( () => import('./pages/Company/Company') );
const EditProcess = lazy( () => import('./pages/Company/EditProcess') );
const EditTravel = lazy( () => import('./pages/Company/EditTravel') );
const EditGroup = lazy( () => import('./pages/Company/EditGroup') );

const App = () => {
  useStore( $userStore );

  const isAuth = !!user.token;
  useEffect( () => {
    getConfig();
    if ( isAuth ) {
      user.get();
    }
  }, [] );

  return (
    <BrowserRouter>
      <RequestIndicator/>
      <Preloader/>
      <LoadIntegrations/>
      <Modal/>
      <Message/>
      <Overlay/>
      <TravelPoliciesModal/>
      <Suspense fallback={ <LoadPage/> }>
        <Routes>
          <Route path="/" element={ <Home/> }/>
          <Route path="/login" element={ <GuestRoute Component={ Login }/> }/>
          <Route path="/registration" element={ <GuestRoute Component={ Registration }/> }/>
          <Route path="/password/forgot" element={ <GuestRoute Component={ ForgotPassword }/> }/>
          <Route path="/password/reset/:token" element={ <GuestRoute Component={ ResetPassword }/> }/>

          <Route path="/search" element={ <AuthRoute Component={ Search }/> }/>
          <Route path="/search/avia" element={ <AuthRoute Component={ AviaSearch }/> }/>
          <Route path="/search/train" element={ <AuthRoute Component={ TrainSearch }/> }/>
          <Route path="/search/hotel" element={ <AuthRoute Component={ HotelSearch }/> }/>

          <Route path="/trips" element={ <AuthRoute Component={ Trips }/> }/>
          <Route path="/trips/:page" element={ <AuthRoute Component={ Trips }/> }/>

          <Route path="/trip/:tripId" element={ <AuthRoute Component={ Trip }/> }/>
          <Route path="/trip/:tripId/blanks/:blankId" element={ <AuthRoute Component={ Pdf }/> }/>

          <Route path="/company/*" element={ <AuthRoute Component={ Company }/> }/>

          <Route path="/company/customers" element={ <AuthRoute Component={ Customers }/> }/>
          <Route path="/company/travel/edit/:id" element={ <AuthRoute Component={ EditTravel }/> }/>
          <Route path="/company/groups/edit/:id" element={ <AuthRoute Component={ EditGroup }/> }/>
          <Route path="/company/process/edit/:id" element={ <AuthRoute Component={ EditProcess }/> }/>

          <Route path="/documents" element={ <AuthRoute Component={ Documents }/> }>
            <Route path=":id/:type" element={ <AuthRoute Component={ Pdf }/> }/>
            <Route path=":page" element={ <AuthRoute Component={ Documents }/> }/>
          </Route>

          <Route path="*" element={ <NotFound/> }/>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default App;
