import React from 'react';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';

export interface IModalProps {
  open?: boolean;
  title?: string;
  content?: any;
  position?: string;
  FirstButton?: any;
  SecondButton?: any;
  hasClose?: boolean;
  zIndex?: number;
  maxWidth?: boolean;
  height?: number;
}

export const modalStoreUpdate = createEvent<IModalProps>();
export const $modalStore = createStore<IModalProps>( {} )
  .on( modalStoreUpdate, ( s, p ) => p );

const Modal = () => {
  const modal = useStore( $modalStore );

  if ( !modal || !modal.open ) {
    return <></>;
  }

  let styles: any = { maxWidth: '600px' };

  if ( modal.maxWidth ) {
    styles.maxWidth = modal.maxWidth;
  }

  if ( modal.height ) {
    styles.height = modal.height;
  }

  let modalStyle: any = {};
  if ( modal.zIndex ) {
    modalStyle.zIndex = modal.zIndex;
  }

  return (
    <div className="modal-wrap modal-welcome" onClick={ () => modalStoreUpdate( {} ) } style={ modalStyle }>
      <div className="modal-content " style={ styles } onClick={ ( e ) => e.stopPropagation() }>

        <div className="modal-header py-3 px-4">
          <h5 className="modal-title">{ modal.title }</h5>
          <div className="close cursor-pointer" onClick={ () => modalStoreUpdate( {} ) }>×</div>
        </div>
        <div className="modal-body">
          { modal.content ? modal.content : '' }

          <div className={ 'd-flex justify-content-' + modal.position }>
            { modal.FirstButton ? modal.FirstButton : '' }
            &nbsp;
            { modal.SecondButton ? modal.SecondButton : '' }

            {/*<button className="btn btn-primary">Скачать</button>*/ }

            { modal.hasClose ?
              <button className="btn btn-link" onClick={ () => modalStoreUpdate( {} ) }>Закрыть</button> : '' }
          </div>
        </div>

      </div>
    </div>
  );
};

export default Modal;
