import React from 'react';
import "./person.scss";
import Html from "../Html";

const TravelPerson = ( props ) => {
	const policy = props.policy;
	const customer = props.customer || {};

	return (
		<div className="travel-person">
			<div className="fz16 fw-500">{ customer.fullname }</div>
			<Html className="fz15">{ policy.message }</Html>
		</div>
	)
}

export default TravelPerson;
