export const getBaseUrl = ( path?: string ) => {
	if ( !path ) path = '';
	if ( !path.startsWith( '/' ) ) path = '/' + path;
	// eslint-disable-next-line no-undef
	return ( process.env.NODE_ENV === 'development' ? process.env.REACT_APP_URL : process.env.REACT_APP_PROD_URL ) + path;
};

export const getApiUrl = ( path?: string ) => {
	if ( !path ) path = '';
	path = path.startsWith( '/' ) ? path : '/' + path;
	return getBaseUrl( '/api' + path );
};

export const getImageUrl = ( path?: string ) => {
	return getBaseUrl( path );
};
