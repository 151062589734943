import { createEvent, createStore } from 'effector';

export const showRequestIndicator = createEvent();
export const hideRequestIndicator = createEvent();

export const $requestIndicator = createStore<boolean>( false )
	.on( showRequestIndicator, () => true )
	.on( hideRequestIndicator, () => false );


export const showPreloader = createEvent();
export const hidePreloader = createEvent();

export const $preloader = createStore<boolean>( true )
	.on( showPreloader, () => true )
	.on( hidePreloader, () => false );