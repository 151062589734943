import { post } from "../util/request";
import { createEvent, createStore } from "effector";

export const defaultConfig = {
	company_name: '',
	company_short_name: '',
	company_inn: '',
	company_ogrn: '',
	company_full_address: '',
	company_phone: '',
	support_phone: '',
	company_email: '',
	company_address: {},
}

export const configStoreUpdate = createEvent()
export const $configStore = createStore( defaultConfig )
	.on( configStoreUpdate, ( s, p ) => p )

export const getConfig = () => {
	post(
		'/config',
		{},
		function ( response ) {
			configStoreUpdate( response );
		}
	)
}
