import React from 'react';
import { Navigate } from 'react-router-dom';
import { user } from "../stores/user";

const GuestRoute = ( { Component } ) => {
	const isAuth = !!user.token;
	return !isAuth ? <Component/> : <Navigate to="/search"/>
}

export default GuestRoute;
