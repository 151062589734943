import React, { lazy, Suspense } from 'react';
import LoadPage from '../components/LoadPage';
import { useSearchParams } from 'react-router-dom';

const KFU = lazy( () => import('./kfu/KFU') );


const LoadIntegrations = () => {
	const [ searchParams ] = useSearchParams();

	return (
		<Suspense fallback={ <LoadPage/> }>
			{ searchParams.get( 'kfuToken' ) && <KFU/> }
		</Suspense>
	);
};

export default LoadIntegrations; 
