import React, { useState } from 'react';

export const overlay = {
	setZIndex: null,
	show: ( zIndex ) => {
		overlay.setZIndex( zIndex );
	},
	hide: () => {
		overlay.setZIndex( 0 );
	}
}

const Overlay = () => {
	const [ zIndex, setZIndex ] = useState( 0 );
	overlay.setZIndex = setZIndex;

	if ( !zIndex ) return <></>;

	return <div style={ { zIndex: zIndex } } className="active overlay"/>
}

export default Overlay;
