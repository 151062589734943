import React from "react";

const Html = ( props ) => {
	const { children, ...newProps } = props;
	return (
		<span { ...newProps } dangerouslySetInnerHTML={ { __html: children } }/>
	);
}

export default Html;
