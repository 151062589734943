import React from 'react';
import { Alert } from '@mui/material';
import { useStore } from 'effector-react';
import { $alert, hideAlert } from '../stores/alert';

const Message = () => {
	const alert = useStore( $alert );

	if ( !alert || !alert.message ) {
		return <></>;
	}

	setTimeout( hideAlert, 10000 );

	return (
		<Alert
			style={ {
				zIndex: 100000
			} }
			onClick={ () => hideAlert() }
			className="alert"
			color={ alert.type }
			variant='standard'
			closeText='x'
		>{ alert.message }</Alert>
	);
};

export default Message;
