import { get, post } from "../util/request";
import { createEvent, createStore } from "effector";

let token = localStorage.getItem( 'token' );
if ( token === 'null' ) token = null;

export const defaultUser = {
	'default': true,
}

export const userStoreUpdate = createEvent()
export const $userStore = createStore( defaultUser )
	.on( userStoreUpdate, ( s, p ) => p )

export const user = {
	token: token,
	email: null,
	password: null,
	get: () => {
		get(
			'/customer',
			function ( response ) {
				userStoreUpdate( response );
			}
		);
	},
	set: ( data, callback ) => {
		post(
			'/user',
			data,
			function ( response ) {
				if ( callback ) callback( response );
			}
		);
	},
	loginToken: ( token ) => {
		localStorage.setItem( 'token', token );
		user.token = token;
		user.get();
	},
	login: ( username, password, callback ) => {
		if ( username ) user.email = username;
		if ( password ) user.password = password;

		post(
			'/login',
			{
				email: user.email,
				password: user.password
			},
			function ( response ) {
				if ( 'token' in response ) {
					localStorage.setItem( 'token', response.token );
					user.token = response.token;
					user.get();
				} else if ( callback ) {
					callback( response );
				}
			}
		)
	},
	logout: () => {
		let isLogged = !!user.token;

		user.token = null;
		user.username = null;
		user.password = null;

		localStorage.removeItem( 'token' );
		if ( isLogged ) {
			window.location.reload();
		}
	}
};
