import moment from 'moment/moment';

export function getTimeFromMins( mins: number ) {
	let hours = Math.trunc( mins / 60 );
	let minutes = mins % 60;
	return [ hours, minutes ];
}

export function parseTime( timeString: string ) {
	if ( !timeString ) return 0;
	let time = timeString.split( ':' );
	return Number( time[ 0 ] ) * 60 + Number( time[ 1 ] );
}

export function parseDate( isoString?: any ) {
	if ( typeof isoString === 'object' && isoString._isAMomentObject ) return isoString;
	if ( !isoString ) return moment();
	if ( typeof isoString === 'number' ) return moment.unix( isoString );
	return moment( isoString );
}

export function diffDate( date1: moment.Moment, date2: moment.Moment ) {
	return date2.diff( date1, 'd' );
}

export function numberFormat( number: number, decimals?: number, dec_point?: string, thousands_sep?: string ) {

	let i, j, kw, kd, km;

	// input sanitation & defaults
	//@ts-ignore
	if ( isNaN( decimals = Math.abs( decimals ) ) ) {
		decimals = 0;
	}

	if ( dec_point === undefined ) {
		dec_point = '.';
	}
	if ( thousands_sep === undefined ) {
		thousands_sep = ' ';
	}

	//@ts-ignore
	i = parseInt( number = ( +number || 0 ).toFixed( decimals ) ) + '';

	if ( ( j = i.length ) > 3 ) {
		j = j % 3;
	} else {
		j = 0;
	}

	km = ( j ? i.substr( 0, j ) + thousands_sep : '' );
	kw = i.substr( j ).replace( /(\d{3})(?=\d)/g, '$1' + thousands_sep );
	//@ts-ignore
	kd = ( decimals ? dec_point + Math.abs( number - i ).toFixed( decimals ).replace( /-/, 0 ).slice( 2 ) : '' );

	return km + kw + kd;
}

export function plural( n: any ) {
	//@ts-ignore
	let args = [ ...arguments ];
	args.shift();
	let idx;
	if ( n % 10 === 1 && n % 100 !== 11 ) {
		idx = 0; // many
	} else if ( n % 10 >= 2 && n % 10 <= 4 && ( n % 100 < 10 || n % 100 >= 20 ) ) {
		idx = 1; // few
	} else {
		idx = 2; // one
	}
	return args[ idx ] || args[ idx - 1 ] || args[ idx - 2 ];
}

export const inArray = ( value: any, array: any[] ) => {
	return array.indexOf( value ) > -1;
};

export const max = ( array: number[] ) => {
	return Math.max.apply( Math, array );
};

export const min = ( array: number[] ) => {
	return Math.min.apply( Math, array );
};

function getRandomInt( min: number, max: number ) {
	min = Math.ceil( min );
	max = Math.floor( max );
	return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
}

export const clone = ( objectOrArray: any ) => {
	return JSON.parse( JSON.stringify( objectOrArray ) );
};

export const serializeObject = ( obj: any ) => {
	let form = new FormData();
	Object.keys( obj ).forEach( function ( key: any ) {
		form.append( key, obj[ key ] );
	} );
	return form;
};
