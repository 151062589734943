import { getBaseUrl } from '../api/helpers';
import { hideRequestIndicator, showRequestIndicator } from '../stores/preloader';
import { apiDelete, apiGet, apiPost } from '../api';


export const getViewUrl = ( path: string ) => {
	return getBaseUrl( path );
};

export function post( url: string, data?: any, callback?: any, errorCallback?: any ) {
	showRequestIndicator();

	apiPost( { url, postData: data } ).then( callback ).catch( errorCallback );
}

export function get( url: string, callback?: any, errorCallback?: any ) {
	showRequestIndicator();
	apiGet( { url } ).then( callback ).catch( errorCallback );
}

export function deleteRequest( url: string, callback?: any, errorCallback?: any ) {
	showRequestIndicator();
	apiDelete( { url } ).then( callback ).catch( errorCallback );
}

export function getHtml( url: string, callback?: any, errorCallback?: any ) {
	showRequestIndicator();
	apiGet( { url } ).then( callback ).catch( errorCallback );
}

export const getFileFromApi = ( url: string, filename: string ) => {
	showRequestIndicator();
	apiGet( { url } ).then( blob => {
		hideRequestIndicator();
		let url = window.URL.createObjectURL( blob );
		let a = document.createElement( 'a' );
		a.href = url;
		a.download = filename;
		document.body.appendChild( a );
		a.click();
		a.remove();
	} );
};
