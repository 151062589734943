import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { $userStore, user } from "../stores/user";
import { useStore } from "effector-react";

const AuthRoute = ( { Component } ) => {
	const isAuth = !!user.token;

	const userData = useStore( $userStore )

	const location = useLocation();
	const navigate = useNavigate();

	useEffect( () => {
		if ( !userData.default && !userData.companyId && location.pathname !== '/company' ) {
			navigate( "/company" );
		}
	}, [ location.pathname ] );

	return isAuth ? <Component/> : <Navigate to="/login"/>
}

export default AuthRoute;
