import React from 'react';
import preloader from '../assets/img/PreloaderTransparent.gif';
import { overflow } from '../util/overflow';
import { useStore } from 'effector-react';
import { $preloader } from '../stores/preloader';

function Preloader() {
	const loading = useStore( $preloader );
	if ( loading ) {
		overflow.hide();
	} else {
		overflow.show();
	}

	return (
		<div className="loader-img" style={ !loading ? { display: 'none' } : {} }>
			<img src={ preloader } alt=''/>
		</div>
	);
}

export default Preloader;
