export const overflow = {
	scroll: 0,
	hide: () => {
		overflow.scroll = window.pageYOffset;
		document.body.classList.add( 'overflow-hidden' );
	},
	show: () => {
		document.body.classList.remove( 'overflow-hidden' );
		if ( overflow.scroll ) setTimeout( () => {
			window.scroll( { top: overflow.scroll } );
		}, 10 );
	}
};
