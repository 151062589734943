import React, { useEffect, useState } from 'react';
import TravelPerson from "../person/TravelPerson";
import "./modal.scss";
import { getCustomersByIds } from "../../stores/customer";
import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";

export const travelModalStoreUpdate = createEvent()
export const $travelModalStore = createStore( null )
	.on( travelModalStoreUpdate, ( s, p ) => p )

const TravelPoliciesModal = () => {
	const state = useStore( $travelModalStore )
	const [ customers, setCustomers ] = useState( [] );

	useEffect( () => {
		if ( state ) {
			getCustomersByIds( state.map( t => t.customerId ), setCustomers )
		}
	}, [ !!state ] )

	if ( !state ) return <></>;

	const close = () => {
		travelModalStoreUpdate( null );
	}

	return (
		<div className="modal-wrap" onClick={ close } style={ { zIndex: 20000 } }>
			<div className="modal-content col-md-8 col-xl-5 px-0" onClick={ ( e ) => e.stopPropagation() }>
				<div className="modal-header p-3">
					<h5 className="modal-title">Есть ограничения тревел политики</h5>
					<div className="close cursor-pointer" onClick={ close }>×</div>
				</div>
				<div className="modal-body mb-4">
					{ state.map( ( policy, i ) =>
						<TravelPerson
							key={ i }
							policy={ policy }
							customer={ customers.find( c => c.id == policy.customerId ) }
						/>
					) }
					<button type="button" className="btn btn-primary btn-sm mt-0 w-auto" data-dismiss="modal"
					        onClick={ close }>Ок
					</button>
				</div>
			</div>
		</div>
	);
};

export default TravelPoliciesModal;
