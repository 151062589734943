import React, { useEffect, useRef } from 'react';
import './request_indicator.scss';
import { useStore } from 'effector-react';
import { $requestIndicator } from '../../stores/preloader';

const RequestIndicator = () => {
	const status = useStore( $requestIndicator );

	const el = useRef<any>();
	useEffect( () => {
		if ( el.current ) {
			setTimeout( () => {
				el.current.classList.add( 'loading' );
			}, 50 );
		}
	} );

	if ( !status ) return <></>;

	return (
		<div ref={ el } className="request-indicator"></div>
	);
};

export default RequestIndicator;
