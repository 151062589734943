import { get, post } from "../util/request";

export const searchCustomers = ( search, callback ) => {
	post(
		'/company/customers/get',
		{ search: search },
		function ( data ) {
			callback( data.customers || [] );
		}
	)
}

export const getCustomersByIds = ( customerIds, callback ) => {
	post(
		'/company/customers/get',
		{ customerIds: customerIds },
		function ( data ) {
			callback( data.customers || [] );
		}
	)
}

export const getCustomer = ( customerId, callback ) => {
	get(
		'/customer/get/' + customerId,
		function ( response ) {
			if ( callback ) callback( response )
		}
	)
}

export const setCustomer = ( data, callback ) => {
	post(
		'/customer/set',
		data,
		function ( response ) {
			if ( callback ) callback( response )
		}
	)
}
